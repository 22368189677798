import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function AdoozyPowerFAQItem(props) {
  const toggle = (e) => {
    props.is_active ? props.updateActiveItem(null) : props.updateActiveItem(props.index);
  }

  return (
    <li
      className="adoozy_power_faqs__list__item"
    >
      <h3
        onClick={e => toggle(e)}
        className="adoozy_power_faqs__list__item__question"
      >
        <span>{props.question}</span>
        {!props.is_active &&
          <button className="adoozy_power_faqs__list__item__question__btn"></button>
        }
        {props.is_active &&
          <button className="adoozy_power_faqs__list__item__question__close_btn"></button>
        }
      </h3>
      <AnimatePresence>
        {props.is_active &&
          <motion.p
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0, transition: { duration: 0.2 } }}
            transition={{ duration: 0.4, type: 'spring' }}
            className="adoozy_power_faqs__list__item__answer"
            dangerouslySetInnerHTML={{ __html: props.answer }}
          ></motion.p>
        }
      </AnimatePresence>
    </li>
  );
}
