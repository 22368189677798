import { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";

const transition = {
  duration: 1,
  // delay: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function HomeCarouselNavItem(props) {
  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      marginBottom: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: i * 0.4 }
    }));
  }, []);

  return (
    <motion.li
      initial={{ marginBottom: '-50px', opacity: 0 }}
      // animate={{ marginBottom: 0, opacity: 1 }}
      animate={controls}
      exit={{ marginBottom: '-50px', opacity: 0 }}
      transition={transition}
      custom={props.index}
      className="home_carousel__nav__item"
    >
      {props.to &&
        <Link className="home_carousel__nav__item__link" to={props.to}>
          <motion.span initial={{ scale: 1 }} whileHover={{ scale: 1.1 }} transition={{ type: 'spring', duration: 0.4 }}>{props.name}</motion.span>
        </Link>
      }
      {props.action &&
        <button className="home_carousel__nav__item__link" onClick={props.action}>
          <motion.span initial={{ scale: 1 }} whileHover={{ scale: 1.1 }} transition={{ type: 'spring', duration: 0.4 }}>{props.name}</motion.span>
        </button>
      }
      <AnimatePresence exitBeforeEnter>
        {props.is_active &&
          <div className="home_carousel__nav__item__timer">
            <motion.div initial={{ left: '-100%' }} animate={{ left: 0 }} exit={{ left: '100%' }} transition={{ duration: 0.3 }} className="home_carousel__nav__item__timer__background">
              <motion.div initial={{ width: 0 }} animate={{ width: '100%' }} transition={{ duration: 7 }} className="home_carousel__nav__item__timer__progress"></motion.div>
            </motion.div>
          </div>
        }
      </AnimatePresence>
    </motion.li>
  );
}
