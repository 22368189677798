import { useRef } from "react";
// import { useEffect, useCallback, useRef } from "react";
// import { ParallaxBanner } from 'react-scroll-parallax';
// import _ from 'lodash';
// import { mouseWheelDirection, scrollToY } from 'UtilsComponent/Utils.js';
// import { useInView } from 'react-intersection-observer';

import PageHeroContent from "./PageHeroContent";
import PageHeroCTA from "./PageHeroCTA";

import './PageHero.scss';

export default function PageHero(props) {
  const ref = useRef();

  const scrollTo = (e) => {
    window.scrollTo({
      top: window.pageYOffset + ref.current.getBoundingClientRect().bottom,
      left: 0,
      behavior: 'smooth'
    });
  }
  // const containerRef = useRef();
  // const { ref, inView } = useInView({
  //   threshold: 0.4,
  // });

  // const handleWindowWheelEvent = useCallback(e => {
  //   if(mouseWheelDirection(e) === 'down' && inView) {
  //     scrollToY(containerRef.current.clientHeight, 1500, 'easeInOutQuint');
  //   }
  // }, [inView, containerRef]);
  //
  // const throttledHandleWindowWheelEvent = useCallback(_.throttle(handleWindowWheelEvent, 50), [inView]);
  // const onWindowMouseWheelEvent = useCallback(e => throttledHandleWindowWheelEvent(e), [inView]);
  //
  // useEffect(() => {
  //   window.addEventListener("wheel", onWindowMouseWheelEvent);
  //
  //   return () => {
  //     window.removeEventListener("wheel", onWindowMouseWheelEvent);
  //   };
  // }, [onWindowMouseWheelEvent]);

  return (
    <div ref={ref} className="page_hero">
      <img 
        className="page_hero__image" 
        src={props.image_large} 
        srcSet={`${props.image_small} 767w, ${props.image_medium} 991w, ${props.image_large} 1920w`}
      />
      <PageHeroContent first_line={props.first_line} second_line={props.second_line} scrollTo={scrollTo} />
      <PageHeroCTA cta_text={props.cta_text} cta_link={props.cta_link} cta_action={props.cta_action} />
      {/* <ParallaxBanner
        className="page_hero"
        layers={[
          {
            image: props.image,
            speed: -20,
          },
          {
            children: <PageHeroContent first_line={props.first_line} second_line={props.second_line} />,
            speed: -20,
            // scale: [1, 0],
            // opacity: [1, 0],
            style: { zIndex: 99 }
          },
          {
            children: <PageHeroCTA cta_text={props.cta_text} cta_link={props.cta_link} cta_action={props.cta_action} />,
            speed: -20,
            style: { zIndex: 99 }
          }
        ]}
        style={{ aspectRatio: '2 / 1' }}
      /> */}
    </div>
  );
}
