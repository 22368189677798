
import './Loading.scss';

export default function Footer(props) {

  return (
    <div className="loading">
      Loading
    </div>
  );
}
