import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

import './PageHeroMobile.scss';

import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';

const transition = {
  duration: 1,
  delay: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const navVariants = {
  initial: { y: '50px', opacity: 0 },
  exit: { opacity: 0 }
};

export default function PageHeroMobile(props) {
  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      y: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: 3+i * 0.5 }
    }));
  }, [controls]);

  return (
    <div className="page_hero_mobile">
      <img
        className="page_hero_mobile__image"
        src={props.image_large} 
        srcSet={`${props.image_small} 767w, ${props.image_medium} 991w, ${props.image_large} 1920w`}
        alt=""
        width=""
        height=""
      />
      <h1 className="page_hero_mobile__title">
        <motion.em
          initial={{ y: '50%', opacity: 0, scale: 0.9 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          exit={{ y: '-50%' }}
          transition={{ ...transition, type: 'spring' }}
          className="page_hero_mobile__title__main"
        >
          {props.first_line}
        </motion.em>
        <motion.span
          initial={{ y: '50%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: '-50%', opacity: 0 }}
          transition={{ ...transition, delay: 1.5, duration: 0.6 }}
          className="page_hero_mobile__title__sub"
        >
          {props.second_line}
        </motion.span>
      </h1>
      <div className="page_hero_mobile__cta">
        <motion.a
          variants={navVariants}
          initial="initial"
          animate={controls}
          whileHover="whileHover"
          custom={0}
          className="page_hero_mobile__cta__btn"
          href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
          target="_blank"
          >
          <img className="page_hero_mobile__cta__btn__image" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
        </motion.a>
        <motion.a
          variants={navVariants}
          initial="initial"
          animate={controls}
          whileHover="whileHover"
          custom={1}
          className="page_hero_mobile__cta__btn"
          href="https://apps.apple.com/us/app/adoozy/id1584497287"
          target="_blank"
        >
          <img className="page_hero_mobile__cta__btn__image" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
        </motion.a>
      </div>
    </div>
  );
}
