// import { intro, copy } from "data/PrivacyPolicy.js";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './TermsAndConditions.scss';

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import Footer from 'components/FooterComponent/Footer';
import Loading from 'components/LoadingComponent/Loading';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

function TermsAndConditionsView() {
  const slug = 'terms-and-conditions';
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));
  }, []);

  if(!data) return <Loading />

  return (
    <Page>
      <Header style={'light'} />
      <div style={{ backgroundColor: 'white' }}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: '100px' }
          }}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <Mobile>
            <h1 style={{ padding: '70px 20px 0', fontFamily: 'Raleway', fontWeight: 700, fontSize: '38px', lineHeight: '45px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C', textTransform: 'uppercase' }}>Terms &amp; Conditions</h1>
          </Mobile>
          <Desktop>
            <h1 style={{ width: '55vw', padding: '100px 0 0', fontFamily: 'Raleway', fontWeight: 700, fontSize: '70px', lineHeight: '82px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C', textTransform: 'uppercase' }}>Terms &amp; Conditions</h1>
          </Desktop>
          <div className="copy" dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </motion.div>
      </div>
      <Footer />
    </Page>
  );
}

export default TermsAndConditionsView;
