import { useState, useEffect } from "react";
import { format } from "date-fns";

import externalLinkIcon from 'assets/svg/external-link-icon.svg';
import closeIcon from 'assets/svg/close-icon.svg';

import Loading from 'components/LoadingComponent/Loading';

function InTheMediaCategoryItem(props) {
    const [topArticles, setTopArticles] = useState(null);
    const [articles, setArticles] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/articles/article_categories/${props.slug}`, {
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json())
            .then(data => {
                setTopArticles(data.splice(0, 5));
                setArticles(data);
            });
    }, []);

    if(!articles) return <Loading />

    return (
        <div className="in_the_media__categories__category">
            <img className="in_the_media__categories__category__image" src={props.image} alt="" />
            <div className="in_the_media__categories__category__content">
                <h3 className="in_the_media__categories__category__content__title">{props.title}</h3>
                <ul className="in_the_media__categories__category__content__links">
                    {topArticles.map((link, index) => {
                        // const date = new Date(link.metadata.date);
                        const date = new Date();

                        return (
                            <li key={index} className="in_the_media__categories__category__content__links__item">
                                <h4 className="in_the_media__categories__category__content__links__item__title">{link.title}</h4>
                                <span className="in_the_media__categories__category__content__links__item__date">{format(date, "d MMMM yyyy")}</span> 
                                <a className="in_the_media__categories__category__content__links__item__link" href={link.metadata.link} target="_blank">Read More <img className="" src={externalLinkIcon} width="14" height="14" alt="View" /></a>
                            </li>
                        );
                    })}
                </ul>
                <button onClick={e => setShowModal(true)} className="in_the_media__categories__category__content__btn">See More</button>
            </div>
            {showModal && 
                <>
                    <div className="in_the_media__categories__category__modal_overlay"></div>
                    <div className="in_the_media__categories__category__modal">
                        <div className="in_the_media__categories__category__modal__content">
                            <button onClick={e => setShowModal(false)} className="in_the_media__categories__category__modal__content__btn">
                                <img className="in_the_media__categories__category__modal__content__btn__image" src={closeIcon} width="20" height="20" alt="Close" />
                            </button>
                            <h3 className="in_the_media__categories__category__modal__content__title">{props.title}</h3>
                            <ul className="in_the_media__categories__category__modal__content__links">
                                {articles.map((link, index) => {
                                    const date = new Date();

                                    return (
                                        <li key={index} className="in_the_media__categories__category__content__links__item">
                                            <h4 className="in_the_media__categories__category__content__links__item__title">{link.title}</h4>
                                            <span className="in_the_media__categories__category__content__links__item__date">{format(date, "d MMMM yyyy")}</span> 
                                            <a className="in_the_media__categories__category__content__links__item__link" href={link.metadata.link} target="_blank">Read More <img className="" src={externalLinkIcon} width="14" height="14" alt="View" /></a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default InTheMediaCategoryItem;
