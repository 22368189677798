import { useState, useEffect } from "react";
// import { hero, intro, eventsGalleries } from "data/Events.js";
import { motion, AnimatePresence } from "framer-motion";
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import PageHero from 'components/PageHeroComponent/PageHero';
import Carousel from 'components/CarouselComponent/Carousel';
import Galleries from 'components/GalleriesComponent/Galleries';
import Footer from 'components/FooterComponent/Footer';
import Loading from 'components/LoadingComponent/Loading';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const canvasStyle = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
}

function EventsView() {
  const slug = 'events';
  const [data, setData] = useState(null);
  const [events, setEvents] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/event-galleries`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setEvents(data));
  }, []);

  if(!data || !events) return <Loading />

  return (
    <Page>
      <Header />
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6, delay: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
        style={canvasStyle}
      >
        <DynamicGradient />
      </motion.div> */}
      <motion.div
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
        style={{ backgroundColor: '#333333' }}
      >
        <PageHero
          first_line={data.metadata.first_line}
          second_line={data.metadata.second_line}
          image_large={data.metadata.image.url}
          image_medium={data.metadata.image.sizes['large']}
          image_small={data.metadata.image.sizes['medium_large']}
        />
      </motion.div>
      <div style={{ backgroundColor: 'white', position: 'relative', zIndex: 2 }}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: '100px' }
          }}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Mobile>
            <p style={{ padding: '70px 20px', fontFamily: 'Raleway', fontWeight: 400, fontSize: '22px', lineHeight: '26px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.metadata.intro}}></p>
          </Mobile>
          <Desktop>
            <p style={{ width: '55vw', padding: '100px 0', fontFamily: 'Raleway', fontWeight: 400, fontSize: '32px', lineHeight: '42px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.metadata.intro}}></p>
          </Desktop>
        </motion.div>
        <Galleries galleries={events} type={'events'} />
        {data.metadata.events_partners.length &&
          <div className="events_partners" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 className="page__title">Our Events Partners</h2>
            <Carousel items={data.metadata.events_partners} />
          </div>
        }
      </div>
      <Footer />
    </Page>
  );
}

export default EventsView;
