import { motion } from "framer-motion";

import AdoozyPowerHowItWorksItemLink from './AdoozyPowerHowItWorksItemLink';

const transition = {
  delay: 1.4,
  duration: 0.6,
  type: 'spring',
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function AdoozyPowerHowItWorksItem(props) {
  // const { ref, inView, entry } = useInView({
  //   threshold: 0.5,
  // });

  return (
    <li className="adoozy_power_how_it_works__list__item">
      <div className="adoozy_power_how_it_works__list__item__content">
        <motion.h3
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 0.5, duration: 0.6 }}
          variants={{
            visible: { y: 0, opacity: 1 },
            hidden: { y: '10%', opacity: 0 }
          }}
          className="adoozy_power_how_it_works__list__item__content__title"
        >
          {props.title}
        </motion.h3>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ delay: 0.8, duration: 0.6 }}
          variants={{
            visible: { y: 0, opacity: 1 },
            hidden: { y: '10%', opacity: 0 }
          }}
          className="adoozy_power_how_it_works__list__item__content__description"
        >
          {props.description}
        </motion.p>
        {props.links &&
          <ul className="adoozy_power_how_it_works__list__item__content__links">
            {props.links.map((link, index) => <AdoozyPowerHowItWorksItemLink key={index} index={index} url={link.url} name={link.name} icon={link.icon.url} action={props.available_actions.hasOwnProperty(link.action) ? props.available_actions[link.action] : null} />)}
          </ul>
        }
      </div>
      <motion.img
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={transition}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0 }
        }}
        className="adoozy_power_how_it_works__list__item__image"
        src={props.image}
        alt=""
        width=""
        height=""
      />
    </li>
  );
}
