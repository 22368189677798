import TextInput from './TextInput';
import EmailInput from './EmailInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';

export default function FieldGroup(props) {

  const renderSwitch = (field, key) => {
    switch(field.type) {
      case 'text': return <TextInput key={key} name={field.name} label={field.label} required={field.required} />;
      case 'email': return <EmailInput key={key} name={field.name} label={field.label} required={field.required} />;
      case 'select': return <SelectInput key={key} name={field.name} label={field.label} required={field.required} options={field.options} />;
      case 'textarea': return <TextAreaInput key={key} name={field.name} label={field.label} required={field.required} />;

      default: return <TextInput key={key} name={field.name} label={field.label} required={field.required} />;
    }
  };

  return (
    <div className="form__fields__group">
      {props.fields.map((field, index) => renderSwitch(field, index))}
    </div>
  );
}
