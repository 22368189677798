import { useState, useEffect } from "react";
// import { intro, categories } from "data/InTheMedia.js";
import { motion } from "framer-motion";

import './InTheMedia.scss';

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import Footer from 'components/FooterComponent/Footer';
import InTheMediaCategoryItem from './InTheMediaCategoryItem';
import Loading from 'components/LoadingComponent/Loading';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

function InTheMediaView() {
  const slug = 'in-the-media';
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    const categoryContent = [];
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));

      fetch(`${process.env.REACT_APP_REST_API_URL}/taxonomy/article_categories`, {
        method: 'GET',
        mode: 'cors'
      }).then(response => response.json())
        .then(data => setCategories(data));
  }, []);

  if(!data || !categories) return <Loading />

  return (
    <Page>
      <Header style={'light'} />
      <div style={{ backgroundColor: 'white' }}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: '100px' }
          }}
          className="in_the_media"
        >
          <h1 className="in_the_media__title">In the Media</h1>
          {data.metadata.videos && data.metadata.videos.length > 0 && 
            <div className="in_the_media__videos">
              {data.metadata.videos.map((video, index) => (
                <a key={index} className="in_the_media__videos__link" href={video.link} target="_blank">
                  <img className="in_the_media__videos__link__thumbnail" src={video.thumbnail.url} alt="View on YouTube" width="" height="" />
                </a>
              ))}
            </div>
          }
          <p className="in_the_media__intro" dangerouslySetInnerHTML={{__html: data.metadata.intro}}></p>
          {categories.terms.length &&
            <div className="in_the_media__categories">
              {categories.terms.map((category, index) => <InTheMediaCategoryItem key={index} slug={category.slug} title={category.name} image={category.metadata.image.sizes.large}  />)}
            </div>
          }
        </motion.div>
      </div>
      <Footer />
    </Page>
  );
}

export default InTheMediaView;
