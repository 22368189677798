import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollToY } from 'components/UtilsComponent/Utils.js';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToY(0, 1500, 'easeInOutQuint');
  }, [pathname]);

  return null;
}
