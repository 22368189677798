import { useEffect, useState } from "react";
import { motion, useAnimation } from 'framer-motion';
import { Mobile } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './HomeCarousel.scss';

import HomeCarouselListItem from './HomeCarouselListItem';
import HomeCarouselNavItem from './HomeCarouselNavItem';

import rightArrowIcon from 'assets/svg/right-arrow-icon.svg';
import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';

const navVariants = {
  initial: { y: '50px', opacity: 0 },
  exit: { opacity: 0 }
};

export default function HomeCarousel(props) {
  const [state, setState] = useState({ active_slide: 0 });

  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      y: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: 3+i * 0.5 }
    }));
  }, [controls]);

  useEffect(() => {
    const timer = setInterval(() => {
      if(props.slides.length-1 <= state.active_slide) {
        setState({ active_slide: 0 });
      } else {
        setState({ active_slide: state.active_slide+1 });
      }
    }, 7000);

    return () => {
      clearInterval(timer);
    };
  }, [state]);

  useEffect(() => {
    if(props.carouselPosition !== null) {
      setState({ active_slide: props.carouselPosition });
    }
  }, [props.carouselPosition]);

  return (
    <div className="home_carousel">
      <ul className="home_carousel__list">
        {props.slides.map((slide, index) => <HomeCarouselListItem key={index} is_active={state.active_slide === index} image_large={slide.image.url} image_medium={slide.image.sizes['large']} image_small={slide.image.sizes['medium_large']} first_line={slide.first_line} second_line={slide.second_line} to={slide.link} action={props.hasOwnProperty(slide.action) ? props[slide.action] : null} />)}
      </ul>
      <ul className="home_carousel__nav">
        {props.slides.map((slide, index) => <HomeCarouselNavItem key={index} index={index} is_active={state.active_slide === index} to={slide.link} name={slide.title} action={props.hasOwnProperty(slide.action) ? props[slide.action] : null} />)}
      </ul>
      <span className="home_carousel__scroll_btn">Scroll for More</span>
      <Mobile>
        <div className="home_carousel__cta">
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            whileHover="whileHover"
            custom={0}
            className="home_carousel__cta__btn"
            href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
            target="_blank"
            >
            <img className="home_carousel__cta__btn__image" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
          </motion.a>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            whileHover="whileHover"
            custom={1}
            className="home_carousel__cta__btn"
            href="https://apps.apple.com/us/app/adoozy/id1584497287"
            target="_blank"
          >
            <img className="home_carousel__cta__btn__image" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
          </motion.a>
        </div>
        {/* <button className="home_carousel__scroll_btn">
          <img className="home_carousel__scroll_btn__image" src={rightArrowIcon} width="" height="" alt="Scroll" />
        </button> */}
      </Mobile>
    </div>
  );
}
