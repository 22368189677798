import { useState, useEffect } from "react";

import TextInput from './TextInput';
import EmailInput from './EmailInput';
import SelectInput from './SelectInput';
import TextAreaInput from './TextAreaInput';
import FieldGroup from './FieldGroup';

import "./Form.scss";

export default function Form(props) {
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState(false);

  const renderSwitch = (field, key) => {
    switch(field.type) {
      case 'text': return <TextInput key={key} name={field.name} label={field.label} required={field.required} />;
      case 'email': return <EmailInput key={key} name={field.name} label={field.label} required={field.required} />;
      case 'select': return <SelectInput key={key} name={field.name} label={field.label} required={field.required} options={field.options} />;
      case 'textarea': return <TextAreaInput key={key} name={field.name} label={field.label} required={field.required} />;
      case 'group': return <FieldGroup key={key} fields={field.fields} />;

      default: return <TextInput key={key} name={field.name} label={field.label} required={field.required} />;
    }
  };

  const send = (event) => {
    event.preventDefault();
    setIsSending(true);

    const input = props.fields.map((field) => {
      if(field.type === 'group') return field.fields.map(group_field => { return {name: event.target[group_field.name].id, value: event.target[group_field.name].value} });
      if(field.type !== 'group') return [{ name: event.target[field.name].id, value: event.target[field.name].value }]
    }).flat();

    fetch(`${process.env.REACT_APP_ADOOZY_REST_API_URL}/forms/enquiry`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subject: props.subheading ? `${props.subject}: ${props.subheading}` : props.subject,
        fields: input
      })
    }).then(response => response.json())
      .then(response => {
        setIsSending(false);

        if(response.hasOwnProperty('success') && response.success === true) {
          clearForm(event.target, props.fields);
          setMessage('Thank you, we\'ve received your enquiry and will be in touch soon.');
        } else {
          setMessage('We were unable to send your enquiry, please try again or email us directly on support@adoozy.co.za.');
        }
      });      
  }

  const clearForm = (form, fields) => {
    fields.forEach(field => {
      if(field.type === 'group') {
        field.fields.forEach(group_field => {
          form[group_field.name].value = '';
        })
      }
      if(field.type !== 'group') form[field.name].value = '';
    });
  }

  return (
    <div className="form">
      {props.title &&
        <h2 className="form__title">{props.title}</h2>
      }
      {props.subheading &&
        <h2 className="form__subheading">{props.subheading}</h2>
      }
      <form className="form__fields" onSubmit={send}>
        {props.fields.map((field, index) => renderSwitch(field, index))}
        <button disabled={isSending} className="form__fields__button btn" style={{ opacity: isSending ? '0.6 ': '1' }}>
          <span className="btn_text">
            {isSending && 
              <>Sending...</>
            }
            {!isSending && 
              <>{props.submit_label}</>
            }
          </span>
          <span className="btn_hover_state_background"></span>
          <span className="btn_hover_state_text">
            {isSending && 
              <>Sending...</>
            }
            {!isSending && 
              <>{props.submit_label}</>
            }
            </span>
        </button>
      </form>
      {message &&
        <p className="form__message">{message}</p>
      }
    </div>
  );
}
