import { useState, useEffect } from 'react';
import { AnimatePresence } from "framer-motion";

import './Home.scss';

import LoadingView from './Loading';
import Header from 'components/HeaderComponent/Header';
import HomeCarousel from 'components/HomeCarouselComponent/HomeCarousel';
import Page from 'components/PageComponent/Page';
import PreLoader from 'components/PreLoaderComponent/PreLoader';

function HomeView(props) {
  const [preloadSrcList, setPreloadSrcList] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const slug = 'home';
  const [imagesPreloaded, setImagesPreloaded] = useState(false); 
  const [data, setData] = useState(null);
  const [carouselPosition, setCarouselPosition] = useState(null);

  useEffect(() => {
    if(preloadSrcList) {
      setTimeout(() => setImagesPreloaded(PreLoader(preloadSrcList)), 3000);
    }
  }, [preloadSrcList]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/preload.cache.php`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setPreloadSrcList(data));

    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));
  }, []);

  const restartCarousel = () => {
    setCarouselPosition(0);
    setTimeout(() => setCarouselPosition(null), 1000);
  }

  // setTimeout(() => setIsLoading(false), 10000);

  if (!imagesPreloaded || !data) {
    return <LoadingView />;
  }

  return (
    <Page className='home__container'>
      <Header restartCarousel={restartCarousel} />
      {/* <AnimatePresence exitBeforeEnter> */}
        <HomeCarousel slides={data.metadata.carousel} carouselPosition={carouselPosition} />
      {/* </AnimatePresence> */}
    </Page>
  );
}

export default HomeView;
