import { galleries } from "data/DopeAF.js";
import { eventsGalleries } from "data/Events.js";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';
import { useParams } from "react-router-dom";

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import PageHero from 'components/PageHeroComponent/PageHero';
import ChildGallery from 'components/ChildGalleryComponent/ChildGallery';
import Footer from 'components/FooterComponent/Footer';
import Loading from 'components/LoadingComponent/Loading';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const canvasStyle = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
}

function GallerySingleView() {
  const { type, slug } = useParams();
  const [gallery, setGallery] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/post-type/${type === 'events' ? 'event-galleries' : 'dope-af-galleries'}/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));
  }, []);

  // useEffect(() => {
  //   setData(gallery);
  // }, [gallery]);

  // useEffect(() => {
  //   let gallery = galleries.filter(gallery => gallery.slug === slug);
  //   if(gallery[0] !== undefined) {
  //     setGallery(gallery[0]);
  //   }

  //   let eventGallery = eventsGalleries.filter(gallery => gallery.slug === slug);
  //   if(eventGallery[0] !== undefined) {
  //     setGallery(eventGallery[0]);
  //   }
  // }, [slug]);

  if(!data) return <Loading />

  return (
    <Page>
      <Header />
      <>
      {data &&
        <>
          {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6, delay: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
            style={canvasStyle}
          >
            <DynamicGradient />
          </motion.div> */}
          <motion.div
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
            style={{ backgroundColor: '#333333' }}
          >
            <PageHero
              first_line={data.title}
              second_line={type === 'events' ? '' : 'Dope AF'}
              image_large={data.metadata.hero_image.url}
              image_medium={data.metadata.hero_image.sizes['large']}
              image_small={data.metadata.hero_image.sizes['medium_large']}
            />
          </motion.div>
          <div style={{ backgroundColor: 'white', position: 'relative', zIndex: 2 }}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={transition}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: '100px' }
              }}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Mobile>
                <p style={{ padding: '70px 20px', fontFamily: 'Raleway', fontWeight: 400, fontSize: '22px', lineHeight: '26px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.content}}></p>
              </Mobile>
              <Desktop>
                <p style={{ width: '55vw', padding: '100px 0', fontFamily: 'Raleway', fontWeight: 400, fontSize: '32px', lineHeight: '42px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.content}}></p>
              </Desktop>
            </motion.div>
          </div>
          <ChildGallery images={data.metadata.gallery} />
        </>
      }
      </>
      <Footer />
    </Page>
  );
}

export default GallerySingleView;
