import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";

import Form from 'components/FormComponent/Form';

import './HeaderMenu.scss';

import logo from 'assets/svg/logo.svg';
import closeMenuIcon from 'assets/svg/close-icon.svg';

const form = {
  title: "Contact Us",
  submit_label: "Submit",
  fields: [
    {
      type: 'group',
      fields: [
        {
          type: 'text',
          label: 'First Name',
          name: 'first_name',
          required: true
        },
        {
          type: 'text',
          label: 'Surname',
          name: 'last_name',
          required: true
        },
      ]
    },
    {
      type: 'group',
      fields: [
        {
          type: 'email',
          label: 'Email',
          name: 'email',
          required: true
        },
        {
          type: 'tel',
          label: 'Phone',
          name: 'phone',
          required: false
        }
      ]
    },
    {
      type: 'textarea',
      label: 'Comment',
      name: 'comment',
      required: false
    }
  ]
};


const navVariants = {
  initial: {
    marginLeft: '-50px',
    opacity: 0
  },
  exit: {
    marginLeft: '-50px',
    opacity: 0
  }
}

const transition = {
  duration: 1,
  stiffness: 50,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const logoTransition = {
  duration: 0.4,
  type: 'spring',
  // stiffness: 100,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const logoVariants = {
  initial: {
    scale: 0.5,
    opacity: 0
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...logoTransition,
      delay: 1
    }
  },
  whileHover: { scale: 1.1 }
};

const navTransition = {
  duration: 1,
  delay: 2,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function HeaderMenu(props) {
  const controls = useAnimation();
  const [isContactActive, setIsContactActive] = useState(false);

  useEffect(() => {
    controls.start((i) => ({
      marginLeft: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: i * 0.5 }
    }));
  }, []);

  function closeMenu() {
    if(isContactActive) toggleIsContactActive();
    else props.toggleIsMenuActive();
  }

  function toggleIsContactActive() {
    setIsContactActive(prevIsContactActive => !prevIsContactActive);
  }

  return (
    <motion.div
      initial={{ left: '-100%' }}
      animate={{ left: 0 }}
      exit={{ left: '-100%' }}
      transition={transition}
      className="header__menu"
    >
      <div className="header__menu__header">
        <Link className="header__menu__header__logo" to="/">
          <motion.img
            variants={logoVariants}
            initial="initial"
            animate="animate"
            transition={logoTransition}
            whileHover="whileHover"
            className="header__menu__header__logo__image"
            src={logo}
            width=""
            height=""
            alt="Adoozy Power On The Go"
          />
        </Link>
        <div className="header__menu__header__content">
          <motion.button
            variants={logoVariants}
            initial="initial"
            animate="animate"
            transition={logoTransition}
            whileHover="whileHover"
            onClick={closeMenu}
            className="header__menu__header__content__close_menu_btn"
          >
            <img className="header__menu__header__content__close_menu_btn__image" src={closeMenuIcon} width="" height="" alt="Close Menu" />
          </motion.button>
        </div>
      </div>
      <div className="header__menu__content">
        <AnimatePresence>
          {!isContactActive &&
            <motion.ul
              initial={{ x: '-100%', y: '-50%', opacity: 0 }}
              animate={{ x: 0, y: '-50%', opacity: 1 }}
              exit={{ x: '-100%', y: '-50%', opacity: 0 }}
              transition={{ duration: 0.2, delay: 0 }}
              className="header__menu__content__list"
            >
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={1}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/">Home</Link>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={1}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/power">Power</Link>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={2}
                className="header__menu__content__list__item">
                <button className="header__menu__content__list__item__link" onClick={props.toggleIsLocationsActive}>Locations</button>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={3}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/events">Events</Link>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={4}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/dope-af">Dope AF</Link>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={5}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/partners">Partners</Link>
              </motion.li>
              <motion.li
                variants={navVariants}
                initial="initial"
                animate={controls}
                exit="exit"
                transition={navTransition}
                custom={6}
                className="header__menu__content__list__item">
                <Link className="header__menu__content__list__item__link" to="/in-the-media">In the Media</Link>
              </motion.li>
            </motion.ul>
          }
        </AnimatePresence>
        {isContactActive &&
          <motion.div
            initial={{ x: '100%', y: '-50%', opacity: 0 }}
            animate={{ x: 0, y: '-50%', opacity: 1 }}
            exit={{ x: '100%', y: '-50%', opacity: 0 }}
            transition={{ duration: 0.8, delay: 1 }}
            className="header__menu__content__contact_form"
          >
            <Form subject={'New Website Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
          </motion.div>
        }
      </div>

      <div className="header__menu__footer">
        <ul className="header__menu__footer__contact_info">
          <li className="header__menu__footer__contact_info__item">Email: <a href="mailto:support@adoozy.co.za">support@adoozy.co.za</a></li>
          <li className="header__menu__footer__contact_info__item">Call: <a href="tel:27105491919">(010) 549 1919</a></li>
        </ul>
        {!isContactActive &&
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => toggleIsContactActive()}
            className="header__menu__footer__contact_btn btn"
          >
            <span className="btn_text">Contact Us</span>
            <span className="btn_hover_state_background"></span>
            <span className="btn_hover_state_text">Contact Us</span>
          </motion.button>
        }
      </div>
    </motion.div>
  );
}
