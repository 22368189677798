import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function PageHeroCTA(props) {

  return (
    <>
      {props.cta_text &&
        <>
          {props.cta_link &&
            <Link to={props.cta_link} className="page_hero__button btn">
              <span className="btn_text">{props.cta_text}</span>
              <span className="btn_hover_state_background"></span>
              <span className="btn_hover_state_text">{props.cta_text}</span>
            </Link>
          }
          {props.cta_action &&
            <button onClick={(e) => props.cta_action(e)} className="page_hero__button btn">
              <span className="btn_text">{props.cta_text}</span>
              <span className="btn_hover_state_background"></span>
              <span className="btn_hover_state_text">{props.cta_text}</span>
            </button>
          }
        </>
      }
    </>
  );
}
