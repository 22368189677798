import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import './Loading.scss';

import DynamicGradient from 'components/DynamicGradientComponent/DynamicGradient';
import Loader from 'components/LoaderComponent/Loader';

const transition = {
  duration: 2,
  ease: [0.43, 0.13, 0.23, 0.96]
}

function LoadingView() {

  return (
    <div className='loading__container'>
      <motion.div
        className='canvas'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1,  transition: { ...transition, delay: 1, duration: 10, type: 'spring' } }}
        exit={{ opacity: 0 }}
        transition={transition}
      >
        <DynamicGradient />
      </motion.div>
      <Loader />
      {/*
        <Link to="/home" className='loading__animation'>
          <Loader />
        </Link>
      */}
    </div>
  );
}

export default LoadingView;
