// import { hero, intro, benefits, form, partners } from "data/Partners.js";
import { form } from "data/Partners.js";
import { useEffect, useState, useRef } from 'react';
import { motion } from "framer-motion";
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './Partners.scss';

import Page from 'components/PageComponent/Page';
import Header from 'components/HeaderComponent/Header';
import PageHero from 'components/PageHeroComponent/PageHero';
import Carousel from 'components/CarouselComponent/Carousel';
import Form from 'components/FormComponent/Form';
import Footer from 'components/FooterComponent/Footer';
import Loading from 'components/LoadingComponent/Loading';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

function PartnersView() {
  const slug = 'partners';
  const [data, setData] = useState(null);
  const ref = useRef();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_API_URL}/page/${slug}`, {
        method: 'GET',
        mode: 'cors'
    }).then(response => response.json())
        .then(data => setData(data));
  }, []);
  
  const goToForm = (e) => {
    window.scrollTo({
      top: window.pageYOffset + ref.current.getBoundingClientRect().top,
      left: 0,
      behavior: 'smooth'
    });
  }

  if(!data) return <Loading />

  return (
    <Page>
      <Header />
      <motion.div
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
        style={{ backgroundColor: '#333333' }}
      >
        <PageHero
          first_line={data.metadata.first_line}
          second_line={data.metadata.second_line}
          image_large={data.metadata.image.url}
          image_medium={data.metadata.image.sizes['large']}
          image_small={data.metadata.image.sizes['medium_large']}
          cta_text={data.metadata.cta_text}
          cta_link={data.metadata.cta_link}
          cta_action={goToForm}
        />
      </motion.div>
      <div style={{ backgroundColor: 'white' }}>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: '100px' }
          }}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Mobile>
            <p style={{ padding: '70px 20px', fontFamily: 'Raleway', fontWeight: 400, fontSize: '22px', lineHeight: '26px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.metadata.intro}}></p>
          </Mobile>
          <Desktop>
            <div style={{ width: '55vw', padding: '100px 0', fontFamily: 'Raleway', fontWeight: 400, fontSize: '32px', lineHeight: '42px', textAlign: 'center', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: data.metadata.intro}}></div>
          </Desktop>
        </motion.div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 0 84px' }}>
          <ul style={{ listStyleType: 'none', width: '78vw', margin: 0, padding: 0 }}>
            {data.metadata.benefits.map((benefit, index) => {
              return (
                <>
                  <Mobile>
                    <motion.li
                      key={index}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={transition}
                      variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: '-100px' }
                      }}
                      style={{ display: 'flex', flexDirection: 'column', margin: '0 0 48px', padding: 0, background: 'white', boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }}
                    >
                      <img style={{ display: 'block', width: '100%' }} src={benefit.image.sizes.medium_large} alt="" width="" height="" />
                      <div style={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '28px 20px' }}>
                        <h3 style={{ margin: '0 0 10px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '18px', lineHeight: '22px', textTransform: 'capitalize', color: '#1C1C1C' }}>{benefit.title}</h3>
                        <p style={{ margin: 0, fontFamily: 'Raleway', fontWeight: 400, fontSize: '20px', lineHeight: '26px', letterSpacing: '-0.02em', color: '#1C1C1C' }}>{benefit.description}</p>
                      </div>
                    </motion.li>
                  </Mobile>
                  <Desktop>
                    <motion.li
                      key={index}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={transition}
                      variants={{
                        visible: { opacity: 1, x: 0 },
                        hidden: { opacity: 0, x: '-100px' }
                      }}
                      style={{ display: 'flex', margin: '0 0 78px', padding: 0, background: 'white', boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }}
                    >
                      <img style={{ display: 'block', flex: 1, width: '50%' }} src={benefit.image.sizes.large} alt="" width="" height="" />
                      <div style={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 74px' }}>
                        <h3 style={{ margin: '0 0 19px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '22px', lineHeight: '26px', textTransform: 'capitalize', color: '#1C1C1C' }}>{benefit.title}</h3>
                        <p style={{ margin: 0, fontFamily: 'Raleway', fontWeight: 400, fontSize: '22px', lineHeight: '32px', letterSpacing: '-0.02em', color: '#1C1C1C' }}>{benefit.description}</p>
                      </div>
                    </motion.li>
                  </Desktop>
                </>
              );
            })}
          </ul>
        </div>
        {data.metadata.partners.length &&
          <div className="partners">
            <h2 className="page__title">Our Partners</h2>
            <Carousel items={data.metadata.partners} />
          </div>
        }
        <Mobile>
          <div ref={ref} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '70px 0', backgroundColor: '#1C1C1C' }}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={transition}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: '300px' }
              }}
              style={{ width: '100vw', padding: '0 20px' }}
            >
              <Form subject={'New Partners Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
            </motion.div>
          </div>
        </Mobile>
        <Desktop>
          <div ref={ref} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '146px 0 165px', backgroundColor: '#1C1C1C' }}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={transition}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: '100px' }
              }}
              style={{ width: '60vw' }}
            >
              <Form subject={'New Partners Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
            </motion.div>
          </div>
        </Desktop>
      </div>
      <Footer />
    </Page>
  );
}

export default PartnersView;
