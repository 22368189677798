import { form } from "data/Partners.js";

import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { Mobile, Tablet, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';

import './Header.scss';

import HeaderMenu from './HeaderMenu';
import Contact from 'components/ContactComponent/Contact';

import logo from 'assets/svg/logo.svg';
import logoBlack from 'assets/svg/logo-black.svg';
import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';
import huaweiDownloadIcon from 'assets/svg/huawei-download-icon.svg';
import menuIcon from 'assets/svg/menu-icon.svg';

const enquiryFormContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#333333'
}

const logoVariants = {
  initial: { scale: 0.5, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  whileHover: { scale: 1.1 }
};

const navVariants = {
  initial: { marginTop: '-50px', opacity: 0 },
  animate: { marginTop: 0, opacity: 1 },
  exit: { marginTop: '-50px', opacity: 0 },
  whileHover: { scale: 1.1, transition: {
    duration: 0.2
  }}
};

const logoTransition = {
  duration: 0.4,
  type: 'spring',
  stiffness: 100,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const navTransition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function Header(props) {
  const controls = useAnimation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isPartnersEnquiryFormActive, setIsPartnersEnquiryFormActive] = useState(false);
  const [containerClasses, setContainerClasses] = useState(['header']);

  useEffect(() => {
    controls.start((i) => ({
      marginTop: 0,
      opacity: 1,
      duration: 0.5,
      transition: { delay: i * 0.5 }
    }));
  }, [controls]);

  useEffect(() => {
    setContainerClasses(prevContainerClasses => {
      let classes = prevContainerClasses.concat([props.style]);
      return classes.filter((item, index) => classes.indexOf(item) === index);
    });
  }, [props.style]);

  function toggleIsMenuActive() {
    setIsMenuActive(prevIsMenuActive => !prevIsMenuActive);
  }

  const logoIconClickHandler = (to) => {
    if(location.pathname === '/') {
      props.restartCarousel();
    } else {
      navigate(to);
    }
  }

  const toggleIsPartnersEnquiryFormActive = () => {
    setIsPartnersEnquiryFormActive(prevIsActive => !prevIsActive);
  }

  return (
    <div className={containerClasses.join(" ")}>
      <button className="header__logo" onClick={e => logoIconClickHandler("/")}>
        <motion.img
          variants={logoVariants}
          initial="initial"
          animate="animate"
          transition={logoTransition}
          whileHover="whileHover"
          className="header__logo__image"
          src={props.style === 'light' ? logoBlack : logo}
          width=""
          height=""
          alt="Adoozy Power On The Go"
        />
      </button>
      <div className="header__content">
        <Desktop>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            // exit="exit"
            whileHover="whileHover"
            // transition={navTransition}
            custom={0}
            className="header__content__cta"
            href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
            target="_blank"
          >
            <img className="header__content__cta__logo" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
          </motion.a>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            // exit="exit"
            whileHover="whileHover"
            // transition={navTransition}
            custom={1}
            className="header__content__cta"
            href="https://apps.apple.com/us/app/adoozy/id1584497287"
            target="_blank"
          >
            <img className="header__content__cta__logo" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
          </motion.a>
          <motion.a
            variants={navVariants}
            initial="initial"
            animate={controls}
            // exit="exit"
            whileHover="whileHover"
            // transition={navTransition}
            custom={2}
            className="header__content__cta"
            href="https://appgallery.huawei.com/app/C107392971"
            target="_blank"
          >
            <img className="header__content__cta__logo" src={huaweiDownloadIcon} width="" height="" alt="Explore it on AppGallery" />
          </motion.a>
        </Desktop>
        <motion.div
          variants={navVariants}
          initial="initial"
          animate={controls}
          // exit="exit"
          // transition={navTransition}
          custom={3}
        >
          <button onClick={e => setIsPartnersEnquiryFormActive(true)} className="header__content__partners_btn btn">
            <span className="btn_text">Partner With Us</span>
            <span className="btn_hover_state_background"></span>
            <span className="btn_hover_state_text">Partner With Us</span>
          </button>
        </motion.div>
        <motion.button
          variants={navVariants}
          initial="initial"
          animate={controls}
          // exit="exit"
          // whileHover="whileHover"
          // transition={navTransition}
          custom={4}
          onClick={toggleIsMenuActive}
          className="header__content__menu_btn"
        >
          <span className="header__content__menu_btn__bar"></span>
          <span className="header__content__menu_btn__bar"></span>
          <span className="header__content__menu_btn__bar"></span>
          {/*<img className="header__content__menu_btn__image" src={menuIcon} width="" height="" alt="Show Menu" />*/}
        </motion.button>
      </div>
      <AnimatePresence>
        {isMenuActive &&
          <HeaderMenu toggleIsMenuActive={toggleIsMenuActive} toggleIsLocationsActive={props.toggleIsLocationsActive} />
        }
      </AnimatePresence>
      <AnimatePresence>
        {isPartnersEnquiryFormActive &&
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ y: '-100%' }}
            transition={{ delay: 0.4, duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
            style={enquiryFormContainerStyle}
          >
            <Contact toggleIsContactActive={toggleIsPartnersEnquiryFormActive} subject={'New Partners Enquiry'} title={form.title} submit_label={form.submit_label} fields={form.fields} />
          </motion.div>
        }
      </AnimatePresence>
    </div>
  );
}
