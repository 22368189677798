import { motion } from "framer-motion";
import { InView, useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive'

import AdoozyPowerHowItWorksItem from './AdoozyPowerHowItWorksItem';

import './AdoozyPowerHowItWorks.scss';

const transition = {
  duration: 0.4,
  delay: 0.4,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function AdoozyPowerHowItWorks(props) {

  return (
    <div className="adoozy_power_how_it_works">
      <h2 className="adoozy_power_how_it_works__title">
        <motion.span
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { x: 0, opacity: 1 },
            hidden: { x: '-100%', opacity: 0 }
          }}
        >
          How It Works
        </motion.span>
      </h2>
      <ul className="adoozy_power_how_it_works__list">
        {props.items.map((item, index) => <AdoozyPowerHowItWorksItem key={index} index={index} available_actions={props} title={item.title} description={item.description} links={item.links} image={item.image.sizes.large} />)}
      </ul>
    </div>
  );
}
