import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ParallaxProvider } from 'react-scroll-parallax';

import 'App.scss';

import HomeView from "views/Home/Home";
import PowerView from "views/Power/Power";
import EventsView from "views/Events/Events";
import DopeAFView from "views/DopeAF/DopeAF";
import GallerySingleView from "views/GallerySingle/GallerySingle";
import PartnersView from "views/Partners/Partners";
import InTheMediaView from "views/InTheMedia/InTheMedia";
import TermsAndConditionsView from "views/TermsAndConditions/TermsAndConditions";

function App() {
  const location = useLocation();

  return (
    <ParallaxProvider>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<HomeView />} />
        <Route path="/power" element={<PowerView />} />
        <Route path="/events" element={<EventsView />} />
        <Route path="/:type/:slug" element={<GallerySingleView />} />
        <Route path="/dope-af" element={<DopeAFView />} />
        <Route path="/partners" element={<PartnersView />} />
        <Route path="/in-the-media" element={<InTheMediaView />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsView />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
