import { useState, useRef } from 'react';
// import { motion } from 'framer-motion';

import ChildGalleryItem from './ChildGalleryItem';

import './ChildGallery.scss';

import prevIcon from 'assets/svg/left-arrow-icon.svg';
import nextIcon from 'assets/svg/right-arrow-icon.svg';

export default function ChildGallery(props) {
  const ref = useRef();
  const [active, setActive] = useState(0);

  const updateActiveImage = (index) => {
    setActive(index);
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const prev = (e) => {
    e.preventDefault();
    setActive(active-1 >= 0 ? active-1 : props.images.length-1);
  }

  const next = (e) => {
    e.preventDefault();
    setActive(active+1 < props.images.length ? active+1 : 0);
  }

  return (
    <div ref={ref} className="child_gallery">
      <div className="child_gallery__image">
        <button onClick={prev} className="child_gallery__image__prev">
          <img src={prevIcon} alt="" width="" height="" />
        </button>
        <img className="child_gallery__image__img" src={props.images[active].url} alt="" width="" height="" />
        <button onClick={next} className="child_gallery__image__next">
          <img src={nextIcon} alt="" width="" height="" />
        </button>
      </div>
      <ul className="child_gallery__thumbnails">
        {props.images.map((image, index) => <ChildGalleryItem key={index} index={index} setActiveImage={updateActiveImage} image={image} />)}
      </ul>
    </div>
  );
}
