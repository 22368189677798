import { useState, useEffect, cloneElement } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
// import { markers } from "data/Locations.js";

import Map from 'components/MapComponent/Map';

import './Page.scss';

const center = {
  lat: -30.014194253902193,
  lng: 24.107046598112337
};

const mapContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#333333'
}

function Page(props) {
  const [isLocationsActive, setIsLocationsActive] = useState(false);
  const [locations, setLocations] = useState([]);

  const toggleIsLocationsActive = () => {
    setIsLocationsActive(prevIsActive => !prevIsActive);
  };

  useEffect(() => {
    fetch('https://payprintapimanagement.azure-api.net/payprintapi/api/v1/adoozypower/getpowertowerlocationsforweb?subscription-key=8b3b093823224aaebbd26d84f7a6b9b6', {
        method: 'GET'
    }).then(response => response.json())
        .then(data => setLocations(data));
  }, []);

  return (
    <>
      {props.children.map((child, index) => cloneElement(child, { key: index, toggleIsLocationsActive: toggleIsLocationsActive }))}
      <AnimatePresence>
        {isLocationsActive &&
          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ x: '-100%' }}
            transition={{ delay: 0.4, duration: 1, ease: [0.43, 0.13, 0.23, 0.96], stiffness: 100 }}
            style={mapContainerStyle}
          >
            {locations.length &&
              <Map center={center} markers={locations} toggleIsLocationsActive={toggleIsLocationsActive} /> 
            }
          </motion.div>
        }
      </AnimatePresence>
    </>
  );
}

export default Page;
