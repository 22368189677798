import { useState } from 'react'

export default function AdoozyPowerProductsItem(props) {

  const showForm = () => {
    props.toggleIsEnquiryFormActive(props.name);
  }

  return (
    <li className="adoozy_power_products__product_list__items__item">
      <img className="adoozy_power_products__product_list__items__item__image" src={props.image} alt="" width="" height="" />
      <div className="adoozy_power_products__product_list__items__item__content">
        <h3 className="adoozy_power_products__product_list__items__item__content__name">{props.name}</h3>
        <p className="adoozy_power_products__product_list__items__item__content__description">{props.description}</p>
        <button
          onClick={showForm}
          className="adoozy_power_products__product_list__items__item__content__enquiry_btn"
        >
          Find out More
        </button>
      </div>
    </li>
  );
}
