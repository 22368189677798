import { Link } from 'react-router-dom';

import './Footer.scss';

import instagramIcon from 'assets/svg/instagram-icon.svg';
import twitterIcon from 'assets/svg/twitter-icon.svg';
import facebookIcon from 'assets/svg/facebook-icon.svg';
import linkedinIcon from 'assets/svg/linkedin-icon.svg';

export default function Footer(props) {

  return (
    <div className="footer">
      <div className="footer__links">
        <div className="footer__links__contact">
          <div className="footer__links__contact__social_links">
            <a className="footer__links__contact__social_links__link" href="https://www.instagram.com/adoozylifestyle/" target="_blank">
              <img src={instagramIcon} alt="Instagram" height="28" />
            </a>
            <a className="footer__links__contact__social_links__link" href="https://twitter.com/AdoozyLifestyle" target="_blank">
              <img src={twitterIcon} alt="Twitter" height="28" />
            </a>
            <a className="footer__links__contact__social_links__link" href="https://www.facebook.com/Adoozylifestyle/" target="_blank">
              <img src={facebookIcon} alt="Facebook" height="28" />
            </a>
            <a className="footer__links__contact__social_links__link" href="https://www.linkedin.com/company/adoozy-power" target="_blank">
              <img src={linkedinIcon} alt="LinkedIn" height="28" />
            </a>
          </div>
          <span>Tel: <a href="tel:27105491919">(010) 549 1919</a></span>
          <span>Email: <a href="mailto:support@adoozy.co.za">support@adoozy.co.za</a></span>
        </div>
        <div className="footer__links__info">
          <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
        </div>
      </div>
      <div className="footer__copyright">
        <span>Copyright &copy; 2022 Adoozy Power. All Rights Reserved.</span>
      </div>
    </div>
  );
}
