import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

import googleDownloadIcon from 'assets/svg/google-download-icon.svg';
import appleDownloadIcon from 'assets/svg/apple-download-icon.svg';

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function AdoozyPowerPricingItem(props) {
  const isDesktop = useMediaQuery({ minWidth: 992 })

  return (
    <li className="adoozy_power_pricing__list__items__item">
      <motion.div
        initial={isDesktop ? "hidden" : null}
        whileInView={isDesktop ? "visible" : null}
        viewport={isDesktop ? { once: true } : null}
        transition={isDesktop ? transition : null}
        variants={isDesktop ? {
          visible: { opacity: 1, y: 0 },
          hidden: { y: '100%', opacity: 0 }
        } : null}
        className="adoozy_power_pricing__list__items__item__card"
      >
        <div className="adoozy_power_pricing__list__items__item__card__info">
          <h3 className="adoozy_power_pricing__list__items__item__card__info__title">{props.title}</h3>
          <span className="adoozy_power_pricing__list__items__item__card__info__price">{props.price}</span>
          <p className="adoozy_power_pricing__list__items__item__card__info__description">{props.description}</p>
        </div>
        <div className="adoozy_power_pricing__list__items__item__card__ctas">
          <a
            className="adoozy_power_pricing__list__items__item__card__ctas__btn"
            href="https://play.google.com/store/apps/details?id=co.za.adoozypower"
            target="_blank"
          >
            <img className="header__content__cta__logo" src={googleDownloadIcon} width="" height="" alt="Get it on Google Play" />
          </a>
          <a
            className="adoozy_power_pricing__list__items__item__card__ctas__btn"
            href="https://apps.apple.com/us/app/adoozy/id1584497287"
            target="_blank"
          >
            <img className="header__content__cta__logo" src={appleDownloadIcon} width="" height="" alt="Get it on App Store" />
          </a>
        </div>
      </motion.div>
    </li>
  );
}
