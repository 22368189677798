// import { useState, useEffect } from 'react';
// import { Parallax } from 'react-scroll-parallax';
// import { range }  from 'UtilsComponent/Utils.js';
// import { useMediaQuery } from 'react-responsive';
import { Mobile, Desktop } from 'components/ResponsiveLayoutsComponent/ResponsiveLayouts.js';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { format } from "date-fns";

// const minLandscapeWidth = window.innerWidth / 100 * 30;
// const maxLandscapeWidth = window.innerWidth / 100 * 39;
// const minPortraitWidth = window.innerWidth / 100 * 25;
// const maxPortraitWidth = window.innerWidth / 100 * 30;
//
// const mobileMinLandscapeWidth = window.innerWidth / 100 * 70;
// const mobileMaxLandscapeWidth = window.innerWidth / 100 * 79;
// const mobileMinPortraitWidth = window.innerWidth / 100 * 55;
// const mobileMaxPortraitWidth = window.innerWidth / 100 * 60;

import arrowIcon from 'assets/svg/right-arrow-black.svg';

const transition = {
  duration: 0.4,
  delay: 0.8,
  ease: [0.43, 0.13, 0.23, 0.96]
}

export default function GalleryItem(props) {
  // const [orientation, setOrientation] = useState(null);
  // const [width, setWidth] = useState(null);
  // const isDesktop = useMediaQuery({ minWidth: 992 });

  // useEffect(() => {
  //   if(isDesktop) {
  //     orientation === 'landscape' ? setWidth(range(minLandscapeWidth, maxLandscapeWidth)) : setWidth(range(minPortraitWidth, maxPortraitWidth));
  //   } else {
  //     orientation === 'landscape' ? setWidth(range(mobileMinLandscapeWidth, mobileMaxLandscapeWidth)) : setWidth(range(mobileMinPortraitWidth, mobileMaxPortraitWidth));
  //   }
  // }, [orientation]);
  //
  // const onImgLoad = ({ target: img }) => {
  //   const { offsetHeight, offsetWidth } = img;
  //   offsetWidth > offsetHeight ? setOrientation('landscape') : setOrientation('portrait');
  // }

  // <motion.li
  //   initial="hidden"
  //   whileInView="visible"
  //   exit="exit"
  //   viewport={{ once: true }}
  //   transition={transition}
  //   variants={{
  //     visible: { opacity: 1, x: 0 },
  //     hidden: { opacity: 0, x: '-100px' },
  //     exit: { opacity: 0, y: '-100px' }
  //   }}
  //   className="galleries__list__item"
  // >
  //   <Parallax speed={range(-10, 20)}>
  //     <Link to={`/dope-af/${props.gallery.slug}`}>
  //       <img className="galleries__list__item__image" onLoad={onImgLoad} src={props.gallery.thumbnail.url} alt="" width={width} height="" />
  //       <h3 className="galleries__list__item__title">{props.gallery.title}</h3>
  //       <span className="galleries__list__item__date">{format(date, "d MMMM yyyy")}</span>
  //     </Link>
  //   </Parallax>
  // </motion.li>

  const date = new Date(props.gallery.date.replace(/-/g, "/"));

  return (
    <>
      <Mobile>
        <motion.li
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: '-100px' }
          }}
          style={{ display: 'flex', flexDirection: 'column', margin: '0 0 48px', padding: 0, background: 'white', boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }}
        >
          <img style={{ display: 'block', width: '100%', aspectRatio: '1 / 1', objectFit: 'cover', objectPosition: 'center' }} src={props.gallery.featured_image} alt="" width="" height="" />
          <div style={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '28px 20px' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0 0 10px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '18px', lineHeight: '22px', textTransform: 'capitalize', color: '#1C1C1C' }}>
              {props.gallery.title}
              <span style={{ marginLeft: '20px', fontFamily: 'Raleway', fontWeight: 400, fontSize: '12px', lineHeight: '22px', letterSpacing: '-0.02em', color: '#1C1C1C', whiteSpace: 'nowrap' }}>{format(date, "d MMMM yyyy")}</span>
            </h3>
            <p style={{ margin: 0, fontFamily: 'Raleway', fontWeight: 400, fontSize: '20px', lineHeight: '26px', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: props.gallery.metadata.intro}}></p>
            <Link to={`/${props.type}/${props.gallery.slug}`} style={{ marginTop: '20px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '12px', lineHeight: '22px', textTransform: 'capitalize', color: '#1C1C1C', textDecoration: 'none' }}>View Gallery</Link>
          </div>
        </motion.li>
      </Mobile>
      <Desktop>
        <motion.li
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={transition}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: '-100px' }
          }}
          style={{ display: 'flex', margin: '0 0 78px', padding: 0, background: 'white', boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }}
        >
          <img style={{ display: 'block', flex: 1, width: '50%', aspectRatio: '1 / 1', objectFit: 'cover', objectPosition: 'center' }} src={props.gallery.featured_image} alt="" width="" height="" />
          <div style={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 74px' }}>
            <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0 0 19px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '22px', lineHeight: '26px', textTransform: 'capitalize', color: '#1C1C1C' }}>
              {props.gallery.title}
              <span style={{ marginLeft: '25px', fontFamily: 'Raleway', fontWeight: 400, fontSize: '14px', lineHeight: '26px', letterSpacing: '-0.02em', color: '#1C1C1C', whiteSpace: 'nowrap' }}>{format(date, "d MMMM yyyy")}</span>
            </h3>
            <p style={{ margin: 0, fontFamily: 'Raleway', fontWeight: 400, fontSize: '22px', lineHeight: '32px', letterSpacing: '-0.02em', color: '#1C1C1C' }} dangerouslySetInnerHTML={{__html: props.gallery.metadata.intro}}></p>
            <Link to={`/${props.type}/${props.gallery.slug}`} style={{ display: 'flex', alignItems: 'center', marginTop: '20px', fontFamily: 'Raleway', fontWeight: 700, fontSize: '16px', lineHeight: '22px', textTransform: 'capitalize', color: '#1C1C1C', textDecoration: 'none' }}>
              View Gallery
              <img style={{ display: 'inline-block', marginLeft: '10px' }} src={arrowIcon} alt="" width="" height="" />
            </Link>
          </div>
        </motion.li>
      </Desktop>
    </>
  );
}
